import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { LineChartData } from '../../../models/dashboard';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { dateTimeFilterFormat } from '../program-dashboard/program-dashboard.helper';

const { logError } = RaygunErrorHandlerService();

export type AdvocateSearchResult = {
  advocate_id: string;
  available: boolean;
  in_chat: boolean;
  name: string;
  img_url: string;
  lived_experiences: string[];
  languages: string[];
  community_names: string[];
  community_ids: string[];
  service_provider_organization_id: string;
  service_provider_organization_name: string;
  service_provider_title: string;
  service_title: string;
  service_description: string;
  org_name: string;
};

type DashboardRequestParams = {
  end_date: Dayjs;
  start_date: Dayjs;
};

export type DateRange = DashboardRequestParams;

type AdvocatesOnlineDashboardData = {
  advocates_online_hourly: LineChartData[];
  online_advocates: AdvocateSearchResult[];
  date_range: DateRange;
};

type AdvocatesOnlineDashboardSliceType = {
  onlineDashboardParams: DashboardRequestParams;
  onlineDashboardData: AdvocatesOnlineDashboardData;
  advocateOnlineApiStatus: APIStatus;
  weekdayWeekendHoursApiStatus: APIStatus;
  weekdayWeekendHoursCSV: string;
};

const initialState: AdvocatesOnlineDashboardSliceType = {
  onlineDashboardParams: {
    start_date: dayjs().subtract(1, 'week'),
    end_date: dayjs(),
  },
  onlineDashboardData: {
    advocates_online_hourly: [],
    online_advocates: [],
    date_range: {
      start_date: dayjs().subtract(1, 'week'),
      end_date: dayjs(),
    },
  },
  advocateOnlineApiStatus: APIStatus.IDLE,
  weekdayWeekendHoursApiStatus: APIStatus.IDLE,
  weekdayWeekendHoursCSV: '',
};

export const loadAdvocateOnlineDashboard = createAsyncThunk<AdvocatesOnlineDashboardData, undefined, AsyncThunkConfig>(
  'advocatesOnlineDashboard/loadAdvocateOnlineDashboard',
  async (_, thunkAPI) => {
    try {
      const { advocatesOnlineDashboardSlice } = thunkAPI.getState();
      const response = await axios.post('v0_advocate_online_dashboard', {
        start_date: advocatesOnlineDashboardSlice.onlineDashboardParams.start_date
          .startOf('day')
          .format(dateTimeFilterFormat),
        end_date: advocatesOnlineDashboardSlice.onlineDashboardParams.end_date
          .endOf('day')
          .format(dateTimeFilterFormat),
      });
      return response as unknown as AdvocatesOnlineDashboardData;
    } catch (e) {
      logError(e, ['advocateOnlineDashboardSlice', 'loadAdvocateOnlineDashboard']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getWeekdayWeekendHours = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'advocatesOnlineDashboard/getWeekdayWeekendHours',
  async (_, thunkAPI) => {
    try {
      const { advocatesOnlineDashboardSlice } = thunkAPI.getState();
      const start_param = advocatesOnlineDashboardSlice.onlineDashboardParams.start_date
        .startOf('day')
        .format(dateTimeFilterFormat);
      const end_param = advocatesOnlineDashboardSlice.onlineDashboardParams.end_date
        .endOf('day')
        .format(dateTimeFilterFormat);
      const response = await axios.post('v0_advocate_weekend_weekday_hours', {
        start_date: start_param,
        end_date: end_param,
      });
      const csvData = response as unknown as string;
      return csvData;
    } catch (e) {
      logError(e, ['advocateOnlineDashboardSlice', 'getWeekdayWeekendHours']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const advocatesOnlineDashboardSlice = createSlice({
  name: 'advocatesOnlineDashboard',
  initialState,
  reducers: {
    handleParamsChange: (state, action: PayloadAction<Partial<DashboardRequestParams>>) => {
      state.onlineDashboardParams = { ...state.onlineDashboardParams, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAdvocateOnlineDashboard.pending, (state, _action) => {
        state.advocateOnlineApiStatus = APIStatus.PENDING;
      })
      .addCase(loadAdvocateOnlineDashboard.fulfilled, (state, action) => {
        state.onlineDashboardData = action.payload;
        const { start_date, end_date } = state.onlineDashboardParams;
        state.onlineDashboardData.date_range = { start_date, end_date };
        state.advocateOnlineApiStatus = APIStatus.FULFILLED;
      })
      .addCase(loadAdvocateOnlineDashboard.rejected, (state, _action) => {
        state.advocateOnlineApiStatus = APIStatus.ERROR;
      })
      .addCase(getWeekdayWeekendHours.pending, (state, _action) => {
        state.weekdayWeekendHoursApiStatus = APIStatus.PENDING;
      })
      .addCase(getWeekdayWeekendHours.fulfilled, (state, action) => {
        state.weekdayWeekendHoursCSV = action.payload;
        state.weekdayWeekendHoursApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getWeekdayWeekendHours.rejected, (state, _action) => {
        state.weekdayWeekendHoursApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleParamsChange } = advocatesOnlineDashboardSlice.actions;
